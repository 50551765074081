import React, { useCallback, useMemo } from "react";
import { Typography } from "@commonsku/styles";
import AvalaraCategorySelect from "../../avalara/AvalaraCategorySelect";
import { isAvalaraShop } from "../../../helpers/order";
import { useShopById, useUpdateShopById } from "../../../redux/hooks/shops";
import { useOrderByNumber, useUpdateOrderById } from "../../../redux/hooks/orders";
import { useCompanyData } from "../../../redux/hooks/company_data";
import { IOrder } from "../../../interfaces/Order";
import Zip2TaxInput from "../../Zip2TaxInput";
import TaxSelect from "../../helpers/TaxSelect";
import { getShopOptions } from "../../../helpers/shop";
import { useSelector } from "react-redux";
import { getTaxDropdown } from "../../../selectors/dropdowns";
import Tax from "../../../models/Tax";

const AggregateTaxConfig = ({ shop_id }: { shop_id: string }) => {
  const shop = useShopById(shop_id);
  const order = useOrderByNumber<IOrder>(shop?.shop_number || '', 'SHOP');
  const company_data = useCompanyData();

  const taxes = useSelector(getTaxDropdown);
  const shopTax = useMemo(
    () => shop?.default_tax_id ? taxes.find(t => t.tax_id === shop.default_tax_id) : undefined,
    [taxes, shop?.default_tax_id]
  );

  const shopOptions = useMemo(
    () => getShopOptions(shop.shop_options),
    [shop.shop_options]
  );

  const onChangeTax = useCallback((v: string) => {
    let cb = () => null;
    if (v === 'zip2tax') {
      if (!shopTax?.zip2tax_id || shopTax?.label === "E") {
        cb = () => onUpdateShop('default_tax_id', shop.default_tax_id, '');
      }
      Promise.resolve(cb()).then(resp => {
        if (resp === null || resp?.type?.includes('UPDATE_SHOP_FAILURE')) return;
        onUpdateShop('shop_options', shop.shop_options, JSON.stringify({ ...shopOptions, use_zip2tax_default: true }));
      });
      return;
    }

    const countries = shopOptions.ship_address_countries || [];
    let new_countries = countries;
    // avalara only allows taxes for US addresses
    if (v === Tax.avalara_tax_id && +shop.tax_custom_ship_address === 1 && !countries.includes('us')) {
      new_countries = ['us'];
    }
    cb = () => onUpdateShop('default_tax_id', shop.default_tax_id, v);
    Promise.resolve(cb()).then(resp => {
      if (resp === null || resp?.type?.includes('UPDATE_SHOP_FAILURE')) return;
      onUpdateShop('shop_options', shop.shop_options, JSON.stringify({
        ...shopOptions,
        use_zip2tax_default: false,
        ...(new_countries.length > 0 ? { ship_address_countries: new_countries } : {}),
      }));
    });
  }, [shop, shopTax, shopOptions]);

  const useZip2Tax = Boolean(!shopTax || shopTax?.zip2tax_id) && +shop.zip2tax === 1 && shopOptions.use_zip2tax_default;

  const onUpdateShop = useUpdateShopById(shop_id);
  const onUpdateOrder = useUpdateOrderById(order?.order_id || '');

  const taxValue = useMemo(() => shop.default_tax_id || '', [shop.default_tax_id]);

  return <>
    <div className="small-12 medium-3 columns" style={{ marginTop: '1rem', marginBottom: '1rem' }}>
      <div>
        <Typography.Text bold fontSize={'16px'} style={{ marginBottom: '5px' }}>Tax Method</Typography.Text>
        <TaxSelect
          value={useZip2Tax ? 'zip2tax' : shop.default_tax_id}
          onChange={onChangeTax}
          tax_included={true}
          include_zip2tax={+shop.zip2tax === 1 || shopOptions.use_zip2tax_default || Boolean(shopTax?.zip2tax_id)}
          avalara={company_data.avalara_enable}
          shouldAlert={false}
          hide_manual_taxes={+shop.tax_custom_ship_address === 1}
        />
      </div>
    </div>
    {useZip2Tax && +shop.tax_custom_ship_address !== 1 ? <div className="small-12 medium-3 columns" style={{ marginTop: '1rem', marginBottom: '1rem' }}>
      <Zip2TaxInput
        label="Zip code"
        value={taxValue}
        onChange={v => {
          onUpdateShop('default_tax_id', shop.default_tax_id, v.value);
        }}
        style={{ height: '41px', marginTop: '6px' }}
        optionsListStyle={{ marginTop: '73px' }}
      />
    </div> : null}
    {isAvalaraShop(shop) ? <div className="small-12 medium-3 columns" style={{ marginTop: '1rem', marginBottom: '1rem' }}>
      <div>
        <Typography.Text bold fontSize={'16px'} style={{ marginBottom: '5px' }}>Default Avalara Category</Typography.Text>
        <AvalaraCategorySelect
          value={order.default_avalara_category_id}
          onChange={(value: string) => onUpdateOrder('default_avalara_category_id', order.default_avalara_category_id, value)}
        />
      </div>
    </div> : null}
  </>;
};

export default AggregateTaxConfig;
